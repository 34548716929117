import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AlertModel {
  isOpen?: boolean
  message?: string
  type?: string
  persist?: boolean
}

const initialState: AlertModel = {
  isOpen: false,
  message: '',
  type: '',
  persist: false,
}

export const alertSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    resetState(state): any {
      Object.assign(state, initialState)
    },
    open(state, { payload }: PayloadAction<AlertModel>): any {
      state.isOpen = true;
      state.message = payload.message;
      state.type = payload?.type || 'success';
      state.persist = payload?.persist || false;
    },
    close(state, { payload }: PayloadAction<AlertModel>): any {
      state.isOpen = false;
      state.persist = false;
    },
  },
})

export default alertSlice.reducer
export const alertActions = {
  ...alertSlice.actions,
}
