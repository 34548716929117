import checkBrowserSupport from '@/utils/checkBrowserSupport'
import { Navigate, useLocation, useRoutes } from 'react-router-dom'
import router, { IRoute } from './index'

const RouterBeforeEach = (props: { route: IRoute; children: any }) => {
  const location = useLocation()
  const routerKey = location.pathname.toLowerCase()

  // Check if the browser meets the requirements
  if (['/app'].find(item => routerKey.includes(item))) {
    const {
      isEquipmentSupport,
      isBrowserSupport,
      isBrowserVersionSupport,
      isBrowserParamsSupport,
      isBrowserModeSupport,
    } = checkBrowserSupport()
    if (
      !isEquipmentSupport ||
      !isBrowserSupport ||
      !isBrowserVersionSupport ||
      !isBrowserParamsSupport ||
      !isBrowserModeSupport
    ) {
      window.alert(
        'The current browser does not support or the specified function is not enabled',
      )
      return <Navigate to={'/'} replace />
    }
  }

  return <>{props.children}</>
}

const renderRoutes = (routes: IRoute[]) => {
  return routes.map((item: IRoute) => {
    const route: any = { meta: item.meta, path: item.path }
    if (item.component) {
      route.element = (
        <RouterBeforeEach route={item}>
          <item.component />
        </RouterBeforeEach>
      )
    }
    if (item.children) {
      route.children = renderRoutes(item.children)
    }
    if (item.redirect) {
      route.element = <Navigate to={item.redirect} />
    }
    return route
  })
}

export default () => {
  return useRoutes(renderRoutes(router.routes as IRoute[]))
}
