const ProjectSaveResponseCode = 'ProjectSaveResponseCode'

export function getProjectSaveResponseCode() {
  return localStorage[ProjectSaveResponseCode]
}

export function setProjectSaveResponseCode(code: number) {
  localStorage[ProjectSaveResponseCode] = code
}

export function removeProjectSaveResponseCode() {
  localStorage.removeItem(ProjectSaveResponseCode)
}

const ProjectSaveResponseCodeChanged = 'ProjectSaveResponseCodeChanged'

export function getProjectSaveResponseCodeChanged() {
  return localStorage[ProjectSaveResponseCodeChanged]
}

export function setProjectSaveResponseCodeChanged(changed: boolean) {
  localStorage[ProjectSaveResponseCodeChanged] = changed
}

export function removeProjectSaveResponseCodeChanged() {
  localStorage.removeItem(ProjectSaveResponseCodeChanged)
}
