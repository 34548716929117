import { configureStore } from '@reduxjs/toolkit'
import app from './app'
import clipeditor from './clipeditor'
import editor from './editor'
import material from './material'
import project from './project'
import quotas from './quotas'
import resource from './resource'
import subscription from './subscription'
import plans from './plans'
import task from './task'
import user from './user'
import purchase from './purchase'
import alert from './alert'
import upgrade from './upgrade'
import uploadCenter from './uploadCenter'
import lifetime from './lifetime'
import coachmarks from './coachmarks'

const store = configureStore({
  reducer: {
    material,
    project,
    resource,
    user,
    app,
    editor,
    clipeditor,
    task,
    subscription,
    quotas,
    plans,
    purchase,
    alert,
    upgrade,
    uploadCenter,
    lifetime,
    coachmarks
  },
  devTools: true,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
