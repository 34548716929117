import * as React from 'react';
import moment from 'moment';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useAppSelector } from '@/store/hooks';
import store from '@/store'
import { purchaseActions } from '@/store/purchase';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function PositionedSnackbar() {
  const dispatch = store.dispatch
  const pathName = window.location.pathname;

  const { subscription: { subscription_status, currency, planInterval, planName, planPrice, unique_code, planId, currentStartDate }, plans: { plans } } = useAppSelector((state: any) => state);

  React.useEffect(() => {
    if (subscription_status === 'pending_payment') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [subscription_status]);

  const [isOpen, setOpen] = React.useState(false);

  const handleSubscriptionRenewal = () => {
    const allPlans = [...plans?.monthly, ...plans?.annual];

    const uniqueCodeWithoutCurrencyArr = unique_code.split('_');
    uniqueCodeWithoutCurrencyArr.pop();
    const uniqueCodeWithoutCurrency = uniqueCodeWithoutCurrencyArr.join('_');

    const plan = allPlans.find((p: any) => {
      const uniqueCodeWithoutCurrencyAr = p?.unique_code.split('_');
      uniqueCodeWithoutCurrencyAr.pop();
      const newUniqueCodeWithoutCurrency = uniqueCodeWithoutCurrencyAr.join('_');
      if (newUniqueCodeWithoutCurrency === uniqueCodeWithoutCurrency) {
        return p;
      }
    });

    if (plan) {
      dispatch(purchaseActions.setPurchaseDetails({
        type: 'NEW_PURCHASE',
        currency: plan?.currency,
        planInterval: plan?.plan_interval,
        planName: plan?.name,
        planPrice: plan?.price,
        unique_code: plan?.unique_code,
        planId: plan?.id
      }))
    }

  }

  const getRemainingDays = () => {
    const remainingDays = moment().diff(moment(currentStartDate), 'days');
    return 2 - remainingDays;
  }

  return (
    <>
      {isOpen && !pathName.includes('buy-subscription') ?
        <Stack spacing={2} sx={{ width: '100%' }} className='fullwidth'>
          {/* <Button variant="outlined" onClick={handleClick}>
        Open success snackbar
      </Button>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          This is a success message!
        </Alert>
      </Snackbar> */}
          <Alert severity="error">
            <p>Oops! Auto-renewal failed. Your subscription will switch to the Free plan in {getRemainingDays()} {getRemainingDays() === 1 ? 'day' : 'days'}.</p>
            <Button variant="contained" onClick={handleSubscriptionRenewal}>Pay now</Button>
          </Alert>

        </Stack>
        : ''}
    </>
  );
}