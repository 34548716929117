import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import '@/assets/style/main.less'
import store from '@/store'
import { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import Loading from './pages/loading/Loading'
import AppRouter from './router/RouterBeforeEach'
import FullWidthSnackbar from "@/component/FullWidthsnackbar";
;(window as any).global = window

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <BrowserRouter basename={import.meta.env.BASE_URL}>
      <Suspense fallback={<Loading />}>
        <FullWidthSnackbar />
        <AppRouter />
      </Suspense>
    </BrowserRouter>
  </Provider>,
)
