import { createSlice } from '@reduxjs/toolkit'

const initialState: {
  uploadData:any
} = {
  uploadData: {},
}

export const uploadCenterSlice = createSlice({
  name: 'uploadCenter',
  initialState,
  reducers: {
    updateUploadInfo(state,{payload}){
      let data = payload[0]
      if(state.uploadData[data.title as keyof typeof state.uploadData]==undefined){
        state.uploadData[data.title as keyof typeof state.uploadData] = data
      }
    },
    deleteUploadedProperty(state,{payload}){
      delete state.uploadData[payload];
    }
  },
})

export const uploadCenterActions = { ...uploadCenterSlice.actions }

export default uploadCenterSlice.reducer