import { remoteApi } from './utils/constant'
import { get, post } from './utils/internalRequest'

export default {
  subscriptionDetails: ({ ...props }) =>
    get({
      url: remoteApi().subscription_details,
      headers: {
        params: { ...props },
      },
    }),

  transactionDetails: ({ ...props }) =>
    get({
      url: remoteApi().transaction_details,
      headers: {
        params: { ...props },
      },
    }),
  getPaymentPlans: ({ ...props }) => {
    let list = document.cookie.split(';')
    let user_country;
    for (let i of list) {
      let str = i.trim();
      if (str.indexOf('countryCode=') == 0) {
        user_country = (str.substring('countryCode='.length, str.length));
      }
    }
    user_country = user_country ? user_country : 'US'
    return get({
      url: remoteApi().payment_plans,
      headers: {
        headers: { countryCode: user_country },
        params: { ...props },
      },
    })
  },

  applyPromoCode: (data: any) =>
    post({
      url: remoteApi().apply_promocode,
      data,
    }),

  createSetupIntent: (data: any) =>
    post({
      url: remoteApi().create_setup_intent,
      data,
    }),

  confirmPayment: (data: any) =>
    post({
      url: remoteApi().confirm_payment,
      data,
    }),

  createPaymentIntent: (data: any) =>
    post({
      url: remoteApi().create_payment_intent,
      data,
    }),

  confirmPaymentIntent: (data: any) =>
    post({
      url: remoteApi().confirm_payment_intent,
      data,
    }),

  changePlan: (data: any) =>
    post({
      url: remoteApi().change_plan,
      data,
    }),

  createResubscriptionOrder: (data: any) =>
    post({
      url: remoteApi().create_resubscription_order,
      data,
    }),

  confirmResubscription: (data: any) =>
    post({
      url: remoteApi().confirm_resubscription,
      data,
    }),

  getPaymentMethods: ({ ...props }) =>
    get({
      url: remoteApi().payment_methods,
      headers: {
        params: { ...props },
      },
    }),

  cancelSubscription: (data: any) =>
    post({
      url: remoteApi().cancel_subscription,
      data,
    }),
  cancelLifetimeSubscription: (data: any) =>
    post({
      url: remoteApi().cancel_lifetime_subscription,
      data,
    }),

  authorizePaymentMethod: (data: any) =>
    post({
      url: remoteApi().authorize_payment_method,
      data,
    }),

  confirmAuthorizePayment: (data: any) =>
    post({
      url: remoteApi().confirm_auth_payment,
      data,
    }),

  notifyDowngraded: (data: any) =>
    post({
      url: remoteApi().notify_downgraded,
      data,
    }),

  lifetimeSubsMeta: ({ ...props }) => get({
    url: remoteApi().lifetime_subscriptions_meta,
    headers: {
      params: { ...props },
    },
  }),
  createLifetimeOrder: (data: any) =>
    post({
      url: remoteApi().create_lifetime_order,
      data,
    })
}
