export default {
  editor: [
    'template',
    'project',
    'videofx',
    'animatedsticker',
    'animatedstickerinanimation',
    'animatedstickeroutanimation',
    'animatedstickeranimation',
    'captionstyle',
    'captioncontext',
    'captionrenderer',
    'captioninanimation',
    'captionoutanimation',
    'captionanimation',
    'theme',
    'transition',
    'm3u8',
    'font',
    'compoundcaption',
    'record',
    'model',
    'resources',
    'backgroundimage',
    'interactproject',
    'projectresource',
    'ARScene',
    'facemesh',
    'warp',
    'audiowave',
    'lic',
    'dat',
  ],
  live: ['m3u8'],
} as { [key: string]: string[] }
