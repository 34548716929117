import quotasApi from '@/http/quotas'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

interface StorageModel {
  max_quota: number
  quota_left: number
  quota_used: string
  quota_used_percentage: string
  feature_exhausted: boolean
}
interface TemplatesModel {
  isLoaded: boolean,
  feature_exhausted: boolean
}

const initialStateStorageModel: StorageModel = {
  max_quota: 0,
  quota_left: 0,
  quota_used: '0',
  quota_used_percentage: '0',
  feature_exhausted: false,
}

const initialStateTemplatesModel: TemplatesModel = {
  feature_exhausted: false,
  isLoaded: false
}

interface QuotasModel {
  STORAGE: StorageModel,
  TEMPLATES: TemplatesModel
}

const initialState: QuotasModel = {
  STORAGE: initialStateStorageModel,
  TEMPLATES: initialStateTemplatesModel,
}

function createExtraActions() {
  return {
    fetchStorageDetails: createAsyncThunk(
      `/storage-details`,
      async (_payload, thunkAPI) => {
        const dispatch = thunkAPI.dispatch
        const [{ feature_exhausted, max_quota, quota_left, quota_used }] =
          await quotasApi.storageDetails({})
        dispatch(
          quotasActions.setStorageDetails({
            feature_exhausted,
            max_quota: Math.floor(max_quota || 0) / 1024 ** 3,
            quota_left: Math.floor((quota_left || 0) / 1024 ** 3),
            quota_used: ((quota_used || 0) / 1024 ** 3).toFixed(2),
            quota_used_percentage: (quota_used / max_quota * 100).toFixed(2)
          }),
        )
      },
    ),
    fetchTemplatesQuota: createAsyncThunk(
      `/templates-quota`,
      async (_payload, thunkAPI) => {
        const dispatch = thunkAPI.dispatch
        const [{ non_quantifiable_status }] =
          await quotasApi.templatesDetails({})


        dispatch(
          quotasActions.setTemplatesQuota({
            feature_exhausted: !non_quantifiable_status,
            isLoaded: true
          }),
        )
      },
    ),
  }
}
const extraActions = createExtraActions()

export const quotasSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    resetState(state): any {
      Object.assign(state, initialState)
    },
    setStorageDetails(state, { payload }: PayloadAction<StorageModel>): any {
      state.STORAGE.max_quota = payload.max_quota
      state.STORAGE.quota_left = payload.quota_left
      state.STORAGE.quota_used = payload.quota_used
      state.STORAGE.feature_exhausted = payload.feature_exhausted
      state.STORAGE.quota_used_percentage = payload.quota_used_percentage
    },
    setTemplatesQuota(state, { payload }: PayloadAction<TemplatesModel>): any {
      state.TEMPLATES.feature_exhausted = payload.feature_exhausted
      state.TEMPLATES.isLoaded = payload.isLoaded
    },
  },
})

export default quotasSlice.reducer
export const quotasActions = {
  ...quotasSlice.actions,
  ...extraActions,
}
