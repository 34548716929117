import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface PlansModel {
  quota_val : number | null,
  action : string,
  event : string,
  quota_info : any,
}

const initialState: PlansModel = {
  quota_val : null,
  action : '',
  event : '',
  quota_info : {}
}

export const upgradeSlice = createSlice({
  name: 'upgrade',
  initialState,
  reducers: {
    resetState(state): any {
      Object.assign(state, initialState)
    },
    setModalInfo(
      state,
      { payload }: PayloadAction<any>,
    ): any {
      state.quota_info=payload.quota_info
      state.quota_val = payload.quota_info?.max_quota
      state.action = payload.action
      state.event = payload.event
    },
  },
})

export default upgradeSlice.reducer
export const upgradeActions = {
  ...upgradeSlice.actions,
}