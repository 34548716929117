import store from '@/store'
import { userActions } from '@/store/user'
import { getToken } from '@/utils/auth'
import EventBus from '@/utils/EventBus'
import {
  getProjectSaveResponseCode,
  setProjectSaveResponseCode,
  setProjectSaveResponseCodeChanged,
} from '@/utils/projectSave'
import axios, { AxiosRequestConfig } from 'axios'
import { remoteApi } from './constant'
import { refreshExpiredToken } from './internalRequest'
import { getApiHost } from './network'

// create an axios instance
const service = axios.create({
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 15000, // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config: any) => {
    // do something before request is sent
    const {
      user: { token },
    } = store.getState()
    if (config?.url?.indexOf(getApiHost('illusto2Backend')) !== -1) {
      config.withCredentials = true;
    }
    if (token) {
      if (import.meta.env.VITE_CONFIG_NAME === 'meishe') {
        config.headers.token = getToken()
      } else {
        config.headers.Authorization = getToken()
      }
    }

    if (config.version) {
      config.headers.version = config.version
    }

    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  },
)

const logout = () => {
  const dispatch = store.dispatch;
  EventBus.$emit('customMessages', {
    message: 'Please login first',
    type: 'warning',
  })
  console.warn('Please login first')
  dispatch(userActions.resetState())
  document.cookie = 'refresh-token=expired; Domain=.illusto.com; expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/; Secure; SameSite=None';
  location.href = '/login'
  return
}

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  async (response) => {
    let { code, msg = '', enMsg = '', message = '' } = response.data

    let saveApi = response.config.url?.indexOf('project/history/save')
    if (saveApi) {
      let prevCode = getProjectSaveResponseCode()
      setProjectSaveResponseCodeChanged(code !== parseInt(prevCode))
      setProjectSaveResponseCode(code)
    }

    const originalRequest = response.config;
    const {
      config: { url },
    } = response
    msg = enMsg || msg || message
    const dispatch = store.dispatch
    // if the custom code is not 0, it is judged as an error.
    if (code !== 0) {
      if (code === -2) {
        // generate new token using refresh token
        const refreshTokenres = await refreshExpiredToken();
        const newAccessToken = refreshTokenres?.data?.token
        if (newAccessToken) {
          dispatch(userActions.setToken(newAccessToken));
          localStorage.setItem('Authorization', newAccessToken);
          if (import.meta.env.VITE_CONFIG_NAME === 'meishe') {
            originalRequest.headers.token = newAccessToken;
          } else {
            originalRequest.headers.Authorization = 'Bearer ' + newAccessToken;
          }
          return service(originalRequest);
        } else {
          logout();
          return
        }
      }
      EventBus.$emit('customMessages', {
        message: `${url} ${msg}`,
        type: 'warning',
      })
      console.warn(msg)
      return Promise.reject(msg)
    } else {
      return response.data
    }
  },
  async (error) => {
    const originalRequest = error.config;

    const dispatch = store.dispatch
    const isQuotasOrDemoEditorUrl = (originalRequest.url.indexOf(getApiHost('demoEditor')) !== -1) || (originalRequest.url.indexOf(getApiHost('quotas')) !== -1)
    // if the status code is 401, it is judged as un authenticated.
    if (error?.response?.status === 401 &&
      originalRequest.url === remoteApi().refresh_token) {
      logout();
      return
    } else if ((error?.response?.status === 401) ||
      (error?.response?.status === 403 && isQuotasOrDemoEditorUrl)) {
      // generate new token using refresh token
      const refreshTokenres = await refreshExpiredToken();
      const newAccessToken = refreshTokenres?.data?.token
      if (newAccessToken) {
        dispatch(userActions.setToken(newAccessToken));
        localStorage.setItem('Authorization', newAccessToken);
        if (import.meta.env.VITE_CONFIG_NAME === 'meishe') {
          originalRequest.headers.token = newAccessToken;
        } else {
          originalRequest.headers.Authorization = 'Bearer ' + newAccessToken;
        }
        return service(originalRequest);
      } else {
        logout();
        return
      }
    }
    console.error('Network Error')
    return Promise.reject(error)
  },
)

export const get = ({
  url,
  headers,
  completeRes,
}: {
  url: string
  headers: AxiosRequestConfig
  completeRes?: Boolean
}) => {
  return service.get(url, headers).then(res => {
    return completeRes ? res : res.data
  })
}

export const post = ({
  url,
  data,
  headers,
  onUploadProgress,
  explicitTimeout,
}: {
  url: string
  data: any
  headers?: any
  onUploadProgress?: any
  explicitTimeout?: any
}) => {
  let options: any = { headers, onUploadProgress }
  if (explicitTimeout) {
    options['timeout'] = explicitTimeout
  }
  return service.post(url, data, options).then(res => {
    return res.data
  })
}

export const delet = ({
  url,
  headers,
}: {
  url: string
  headers: AxiosRequestConfig
}) => {
  return service.delete(url, headers).then(res => {
    return res.data
  })
}
