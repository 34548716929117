import userApi from '@/http/user'
import {
  getToken,
  getUserId,
  removeToken,
  removeUserId,
  setToken as _setToken,
  setUserId as _setUserId,
} from '@/utils/auth'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IUserModel {
  token: string
  userId: string
  userName: string
  email: string
}

const initialState: IUserModel = {
  token: getToken(),
  userId: getUserId(),
  userName: '',
  email: '',
}

function createExtraActions() {
  return {
    fetchUserInfo: createAsyncThunk(
      `user/getAll`,
      async () => await userApi.userInfo({}),
    ),
    fetchUserDetails: createAsyncThunk(
      `user-details`,
      async (_payload, thunkAPI) => {
        const dispatch = thunkAPI.dispatch
        const { name, email, javaUserId } = await userApi.userDetails({})
        dispatch(userActions.updateUserName(name))
        dispatch(userActions.updateUserEmail(email))
        dispatch(userActions.updateUserId(javaUserId))
      },
    ),
  }
}
const extraActions = createExtraActions()

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetState(state): any {
      Object.assign(state, initialState)
      removeToken()
      removeUserId()
    },
    setToken(state, { payload }: PayloadAction<string>): any {
      state.token = payload
      _setToken(payload)
    },
    updateUserId(state, { payload }: PayloadAction<string>): any {
      state.userId = payload
      _setUserId(payload)
    },
    updateUserName(state, { payload }: PayloadAction<string>): any {
      state.userName = payload
    },
    updateUserEmail(state, { payload }: PayloadAction<string>): any {
      state.email = payload
    },
  },
  extraReducers: builder => {
    builder.addCase(
      extraActions.fetchUserInfo.fulfilled,
      (state, action: PayloadAction<{ userId: string; nickName: string }>) => {
        state.userId = action.payload.userId
        state.userName = action.payload.nickName
      },
    )
  },

  // effects: {
  //   *fetchUserInfo({ payload }, { put, call }) {
  //     try {
  //       const userInfo: { [key: string]: any } = yield call(userApi.userInfo)
  //       yield put({ type: 'updateUserId', payload: userInfo.userId })
  //       yield put({ type: 'updateUserName', payload: userInfo.nickName })
  //     } catch (error) {
  //       console.error(error)
  //     }
  //   },
  // },
})

export default userSlice.reducer
export const userActions = { ...userSlice.actions, ...extraActions }
