const TokenKey = 'Authorization'

export function getToken() {
  return localStorage[TokenKey]
}

export function setToken(token: string) {
  localStorage[TokenKey] = token
}

export function removeToken() {
  localStorage.removeItem(TokenKey)
}

const UserId = 'userId'

export function getUserId() {
  return localStorage[UserId]
}

export function setUserId(userId: string) {
  localStorage[UserId] = userId
}

export function removeUserId() {
  localStorage.removeItem(UserId)
}
