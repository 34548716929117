//@ts-nocheck
import { createIntl, IntlProvider, IntlShape } from 'react-intl'
import en from './components/en-US.json'
import zh from './components/zh-CN.json'

let gIntl: IntlShape
// default language
let defaultLanguage = 'en'
// current language
const currentLocalName = localStorage.getItem('editor__lang') || defaultLanguage

// The language configuration is not stored locally, and one is added by default
if (!localStorage.getItem('editor__lang')) {
  localStorage.setItem('editor__lang', defaultLanguage)
}

function flattenMessages(nestedMessages, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    let value = nestedMessages[key]
    let prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      messages[prefixedKey] = value
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }

    return messages
  }, {})
}

export const localeInfo = {
  en: {
    messages: flattenMessages(en),
    locale: 'en',
    momentLocale: '',
  },
  'zh-cn': {
    messages: flattenMessages(zh),
    locale: 'zh-cn',
    momentLocale: 'zh-cn',
  },
} as { [key: string]: any }

const currentLocaleInfo = localeInfo[currentLocalName]

export const LocaleProvider = (props: any) => {
  return <IntlProvider locale={getLocale()}>{props.children}</IntlProvider>
}

const getIntl = (locale, changeIntl) => {
  // If the global gIntl exists and is not a setIntl call
  if (gIntl && !changeIntl && !locale) {
    return gIntl
  }
  // If present in localeInfo
  if (locale && localeInfo[locale]) {
    return createIntl(localeInfo[locale])
  }

  // use default language
  if (localeInfo[defaultLanguage])
    return createIntl(localeInfo[defaultLanguage])
  // use zh-CN
  if (localeInfo['zh-cn']) return createIntl(localeInfo['zh-cn'])
  if (!locale || !!localeInfo[locale]) {
    throw new Error(
      'The current popular language does not exist, please check the locales folder!',
    )
  }
  // If not yet, return an empty
  return createIntl({
    locale: 'zh-cn',
    messages: {},
  })
}

/**
 * toggle global intl settings
 * @param locale key
 */
const setIntl = locale => {
  gIntl = getIntl(locale, true)
}

/**
 * Get the currently selected language
 * @returns string
 */
export function getLocale() {
  return currentLocalName
}

/**
 * switch language
 * @param lang key
 * @returns string
 */
export const setLocale = (lang: string) => {
  if (typeof window.localStorage !== 'undefined') {
    window.localStorage.setItem('editor__lang', lang || '')
  }
  window.location.reload()
}

/**
 * language switch
 * @param descriptor
 * @param values
 */
export const formatMessage = (descriptor, values?) => {
  if (!gIntl) {
    setIntl(getLocale())
  }
  return gIntl.formatMessage(descriptor, values)
}

/**
 * Get current language information
 * @returns
 */
export const getLocaleInfo = () => {
  return currentLocaleInfo
}
