import { SubscriptionModel } from "@/store/subscription";
import moment from "moment";

export const LIFETIME_PLAN_SUBSTRING = 'lifetime_';
export const LIFETIME_PLAN_CANCEL_DAYS_LIMIT = 7;
export const APP_SUMO_DAYS_LIMIT = 60;
export const DOWNGRADED_PLAN_GRACE_LIMIT = 5;

function checkIfLifetimePlan(unique_code: string) {
  return unique_code?.toLowerCase().indexOf(LIFETIME_PLAN_SUBSTRING) !== -1;
}

export function checkIfLifetimeOrAppsumoPlan(subscription: SubscriptionModel) {
  const { unique_code, partnerCode } = subscription;
  return checkIfLifetimePlan(unique_code) || (partnerCode === 'SUMO');
}

export function showLifetimePlan(subscription: SubscriptionModel) {
  // Checking if plan is downgraded to FREE and 5 days has exceeded.
  // if ((subscription?.unique_code === 'FREE') && subscription?.lastDowngradedPlanName) {
  //   const dayDiff = moment().diff(subscription?.lastDowngradedPlanEndDate, 'days');
  //   if (dayDiff >= DOWNGRADED_PLAN_GRACE_LIMIT) {
  //     return true;
  //   }
  //   return false;
  // } else if (subscription?.unique_code === 'FREE') {
  //   return true;
  // }
  return false;
}

export default checkIfLifetimePlan;