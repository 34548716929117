import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import loadingStyle from './loading.module.less'
export default function Loading() {
  return (
    <div className={loadingStyle.loading_box}>
      <Backdrop sx={{ zIndex: theme => theme.zIndex.drawer + 1 }} open={true}>
        <CircularProgress color="inherit" />
        {/* <Image src={new URL('@/assets/image/Loading.gif', import.meta.url).href} /> */}
      </Backdrop>
    </div>
  )
}
