import defaultCoverBase64 from '@/assets/image/base64/defaultCover'
import defaultImageBase64 from '@/assets/image/base64/defaultImage'
import emptyImageBase64 from '@/assets/image/base64/emptyImage'
import transparentBase64 from '@/assets/image/base64/transparent'
import Enum from '@/utils/enum'

export const supportedSplitClipType = [
  'mp4',
  'mov',
  'avi',
  'mpeg',
  'wmv',
  'mts',
]

export const supportedTextVideosType = [
  'mp4',
  'mov',
  'avi',
  'mpeg',
  'wmv',
  'png',
  'jpg',
  'jpeg',
  'jpe',
  'bmp',
  'tga',
]

const fontTypeArr = ['eot', 'otf', 'fon', 'font', 'ttf', 'ttc', 'woff']
export const supportedFontType = fontTypeArr.concat(
  fontTypeArr.map(item => item.toUpperCase()),
)

export const splitOriginalTrackIndex = 0

export const countdownNumber = 60

export const MICROSECONDS_PER_FRAME = 40000 // One frame 40000μs

export const DEFAULT_MATERIAL_DURATION = 5 * 1000000 // The default is 5 seconds for no-length material (except audio and video)

export const CAPTIONDEFAULTLENGTH = 5 * 1000000 // Subtitles are five seconds long by default
// The default length of subtitle animation is 2.5 seconds
export const CAPTIONANIMATIONDEFAULTLENGTH = 0.5 * 1000000
// The default length of sticker animation is 2.5 seconds
export const STICKERANIMATIONDEFAULTLENGTH = 0.5 * 1000000
// The default animation length is 5 seconds
export const ANIMATIONDEFAULTLENGTH = 0.5 * 1000000
// The default picture length is one day 3600 * 24 * 100 * 1000000
export const PICTUREDEFAULTLENGTH = 8640000 * 1000000

/**
 * rulingCount: The interval between each display time
 * tip: The length of time each interval represents rulingSpace The length of each interval px
 * tipSpace: The number of frames represented by each interval 25 frames is one second, hour, minute, second or hexadecimal
 */
export const TLRules = [
  {
    index: 0,
    message: '1 hour',
    rulingCount: 10,
    rulingSpace: 14,
    tip: '0.1 hours',
    start: '01:00:00:00',
    tipSpace: 90000,
  },
  {
    index: 1,
    message: '30 minutes',
    rulingCount: 10,
    rulingSpace: 24,
    tip: '3 minutes',
    start: '00:30:00:00',
    tipSpace: 4500,
  },
  {
    index: 2,
    message: '10 minutes',
    rulingCount: 10,
    rulingSpace: 18,
    tip: '1 minute',
    start: '00:10:00:00',
    tipSpace: 1500,
  },
  {
    index: 3,
    message: '5 minutes',
    rulingCount: 10,
    rulingSpace: 18,
    tip: '0.5 minutes',
    start: '00:05:00:00',
    tipSpace: 750,
  },
  {
    index: 4,
    message: '2 minutes',
    rulingCount: 10,
    rulingSpace: 14,
    tip: '0.2 minutes',
    start: '00:02:00:00',
    tipSpace: 300,
  },
  {
    index: 5,
    message: '1 minute',
    rulingCount: 10,
    rulingSpace: 16,
    tip: '0.1 minutes',
    start: '00:01:00:00',
    tipSpace: 150,
  },
  {
    index: 6,
    message: '30 seconds',
    rulingCount: 10,
    rulingSpace: 9,
    tip: '3 seconds',
    start: '00:00:30:00',
    tipSpace: 75,
  },
  {
    index: 7,
    message: '30 seconds',
    rulingCount: 10,
    rulingSpace: 15,
    tip: '3 seconds',
    start: '00:00:30:00',
    tipSpace: 75,
  },
  {
    index: 8,
    message: '10 seconds',
    rulingCount: 10,
    rulingSpace: 13,
    tip: '1 second',
    start: '00:00:10:00',
    tipSpace: 25,
  },
  {
    index: 9,
    message: '10 seconds',
    rulingCount: 10,
    rulingSpace: 21,
    tip: '1 second',
    start: '00:00:10:00',
    tipSpace: 25,
  },
  {
    index: 10,
    message: '4 seconds',
    rulingCount: 10,
    rulingSpace: 11,
    tip: '0.4 seconds',
    start: '00:00:04:00',
    tipSpace: 10,
  },
  {
    index: 11,
    message: '2 seconds',
    rulingCount: 10,
    rulingSpace: 11,
    tip: '0.2 seconds',
    start: '00:00:02:00',
    tipSpace: 5,
  },
  {
    index: 12,
    message: '1 second',
    rulingCount: 5,
    rulingSpace: 22,
    tip: '0.2 seconds',
    start: '00:00:01:00',
    tipSpace: 5,
  },
  {
    index: 13,
    message: '1 second',
    rulingCount: 5,
    rulingSpace: 36,
    tip: '0.2 seconds',
    start: '00:00:01:00',
    tipSpace: 5,
  },
  {
    index: 14,
    message: '10 frames',
    rulingCount: 10,
    rulingSpace: 18,
    tip: 'One frame',
    start: '00:00:00:10',
    tipSpace: 1,
  },
]

export const SIZE_LIST = [
  '16:9',
  '1:1',
  '9:16',
  '4:3',
  '3:4',
  '18:9',
  '9:18',
  '21:9',
  '9:21',
]

export const KEYFRAME_AUDIO = ['Left Gain', 'Right Gain']

export const defaultCaptionRendererInfo = {
  id: '',
  displayName: 'default text',
  displayNamezhCN: 'Default text',
  installed: true,
  materialType: Enum.mType.captionstyle,
  packageUrl: '.captionrenderer',
}

export const compoundCaptionInfo = {
  displayName: 'Combination',
  displayNamezhCN: 'Combined subtitles',
}

export const transitionInfo = {
  displayName: 'General',
  displayNamezhCN: 'Universal',
}

export const videoTypes = [
  'mp4',
  'mkv',
  'mts',
  'm2ts',
  'avi',
  'flv',
  'mov',
  'm2p',
  'mpg',
  'rm',
  'rmvb',
  'wmv',
  'vob',
  '3gp',
  'gif',
  'mxf',
  'm4v',
  'webm',
]
export const audioTypes = ['mp3', 'wav', 'aac', 'ac3', 'm4a', 'flac', 'opus']
export const imageTypes = ['png', 'jpg', 'jpeg', 'jpe', 'bmp']

export const timelineControlbarHeight = 50

// The default is 10 minutes, which is 15000 frames
export const timelineDurationInFrame = 15000

export const leftBoxWidth = 140

export const editPanelMenuObj = {
  video: [
    { label: 'editPanelMenus.video.video', key: 'video' },
    { label: 'editPanelMenus.video.audio', key: 'audio' },
    { label: 'editPanelMenus.video.colorFilter', key: 'colorFilter' },
    { label: 'editPanelMenus.video.effects', key: 'effects' },
    { label: 'editPanelMenus.video.animationVideofx', key: 'animationVideofx' },
    // { label: 'Sprinkle', key: 'keyer' },
    // { label: 'background', key: 'background' },
  ],
  audio: [{ label: 'editPanelMenus.audio.audio', key: 'audio' }],
  sticker: [
    { label: 'editPanelMenus.sticker.basic', key: 'basic' },
    {
      label: 'editPanelMenus.sticker.animationSticker',
      key: 'animationSticker',
    },
  ],
  caption: [{ label: 'editPanelMenus.caption.text', key: 'text' }],
  'caption-modular': [
    { label: 'editPanelMenus.caption-modular.text', key: 'text' },
    // { label: 'editPanelMenus.caption-modular.renderer', key: 'renderer' },
    // { label: 'editPanelMenus.caption-modular.context', key: 'context' },
    {
      label: 'editPanelMenus.caption-modular.animation',
      key: 'animation',
    },
  ],
  compoundCaption: [
    { label: 'editPanelMenus.compoundCaption.text', key: 'text' },
  ],
  videofx: [{ label: 'editPanelMenus.videofx.basic', key: 'basic' }],
  transition: [{ label: 'editPanelMenus.transition', key: 'transition' }],
  effectfx: [{ label: 'editPanelMenus.effectfx.basic', key: 'basic' }],
  particle: [{ label: 'editPanelMenus.particle.basic', key: 'basic' }],
  adjust: [{ label: 'editPanelMenus.adjust.basic', key: 'basic' }],
  mask: [{ label: 'editPanelMenus.mask.basic', key: 'basic' }],
  aiRecognition: [
    { label: 'editPanelMenus.aiRecognition.basic', key: 'basic' },
  ],
  template: [{ label: 'editPanelMenus.template.basic', key: 'basic' }],
  musicLyrics: [
    { label: 'editPanelMenus.musicLyrics.content', key: 'content' },
    { label: 'editPanelMenus.musicLyrics.style', key: 'style' },
  ],
}

export const defaultCover = defaultCoverBase64
export const defaultImage = defaultImageBase64
export const emptyImage = emptyImageBase64
export const transparentImage = transparentBase64

export const videoTrackHeight = 60
export const normalTrackHeight = 30

export const autoSaveInterval = 5 * 1000
export const checkInternetInterval = 100

export const minSdkVersionTemplateSupported = '2.18.0'

export const TEMPLATE_PACKAGES = [
  'caption',
  'compoundcaption',
  'transition',
  'captionstyle',
  'captionrenderer',
  'captioncontext',
  'captioninanimation',
  'captionoutanimation',
  'captionanimation',
  'animatedsticker',
  'animatedstickerinanimation',
  'animatedstickeroutanimation',
  'animatedstickeranimation',
  'videofx',
  'resources',
]

export const SEEKBAR_OFFSET = 20

export const materialPageSize = 20

export const subtitlesSeekOffsetTime = 50000

export const materialBoxMinWidth = 338
export const materiaBoxMinWidth = 295

export const clipControlCanvasId = 'clipControlCanvas'
export const ASPECT_RATIO_FILTERS = [
  {
    key: '1:1',
    value: 'ASPECT_1_BY_1',
  },
  {
    key: '16:9',
    value: 'ASPECT_16_BY_9',
  },
  {
    key: '9:16',
    value: 'ASPECT_9_BY_16',
  },
  {
    key: '21:9',
    value: 'ASPECT_21_BY_9',
  },
  {
    key: '3:4',
    value: 'ASPECT_3_BY_4',
  },
]

export const ONE_PAYMENT_LINK_ERROR_CODE = 21165;
export const CARD_AUTH_REQUIRED = {
  MSG: 'card authentication required',
  LABEL: 'This card doesn’t support auto-recurring payments. Please try another card',
}