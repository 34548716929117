import subscriptionApi from '@/http/subscription'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface SubscriptionModel {
  subscriptionId?: number,
  subscription_status: string
  unique_code: string,
  planId: string,
  planName: string,
  planPrice: string,
  planInterval: string,
  currency: string,
  cardIssuer: string,
  cardNetwork: string,
  cardType: string,
  cardLast4: string,
  vpa: string,
  paymentMethod: string,
  nextPaymentDueDate: string,
  partnerCode?: string,
  upcomingSubscription?: any,
  currentStartDate?: string,
  currentEndDate?: string,
  downgraded?: boolean,
  downgradedPlanName?: string,
  lastDowngradedPlanName?: string,
  lastDowngradedPlanEndDate?: string,
  isLoaded?: boolean,
  event?: string,
  eventDetails?: object,
}

const initialState: SubscriptionModel = {
  subscription_status: '',
  unique_code: '',
  planId: '',
  planName: '',
  planPrice: '',
  planInterval: '',
  currency: '',
  cardIssuer: '',
  cardNetwork: '',
  cardType: '',
  cardLast4: '',
  vpa: '',
  paymentMethod: '',
  nextPaymentDueDate: '',
  partnerCode: '',
  upcomingSubscription: null,
  currentStartDate: '',
  currentEndDate: '',
  downgraded: false,
  downgradedPlanName: '',
  lastDowngradedPlanName: '',
  lastDowngradedPlanEndDate: '',
  isLoaded: false,
}

function createExtraActions() {
  return {
    fetchSubscriptionDetails: createAsyncThunk(
      `/subscription-details`,
      async ({ event = '', eventDetails }: any, thunkAPI) => {
        const dispatch = thunkAPI.dispatch
        const { id, subscription_status, unique_code, name, price, currency, cardIssuer, cardNetwork, cardLast4, cardType, vpa, paymentMethod, plan_interval: planInterval, nextPaymentDueDate, partnerDetails, upcomingSubscription, plan_id, current_start_date: currentStartDate, current_end_date: currentEndDate, downgraded, downgradedPlanName, lastDowngradedPlanName, lastDowngradedPlanEndDate } =
          await subscriptionApi.subscriptionDetails({})

        dispatch(
          subscriptionActions.setSubscriptionDetails({
            subscriptionId: id,
            subscription_status: subscription_status,
            unique_code: unique_code,
            planId: plan_id,
            planName: name,
            planPrice: price,
            currency: currency,
            cardIssuer, cardNetwork, cardLast4, cardType, vpa, paymentMethod,
            planInterval,
            nextPaymentDueDate,
            partnerCode: partnerDetails?.code || '',
            upcomingSubscription,
            currentStartDate,
            currentEndDate,
            downgraded,
            downgradedPlanName,
            lastDowngradedPlanName,
            lastDowngradedPlanEndDate,
            event,
            eventDetails
          }),
        )
      },
    ),
  }
}
const extraActions = createExtraActions()

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    resetState(state): any {
      Object.assign(state, initialState)
    },
    setSubscriptionDetails(
      state,
      { payload }: PayloadAction<SubscriptionModel>,
    ): any {
      state.subscription_status = payload.subscription_status
      state.unique_code = payload.unique_code
      state.planName = payload.planName
      state.currency = payload.currency
      state.planPrice = payload.planPrice
      state.planId = payload.planId
      state.planInterval = payload.planInterval
      state.cardIssuer = payload.cardIssuer
      state.cardNetwork = payload.cardNetwork
      state.cardLast4 = payload.cardLast4
      state.cardType = payload.cardType
      state.vpa = payload.vpa
      state.paymentMethod = payload.paymentMethod
      state.nextPaymentDueDate = payload.nextPaymentDueDate
      state.partnerCode = payload.partnerCode;
      state.upcomingSubscription = payload.upcomingSubscription;
      state.currentStartDate = payload.currentStartDate;
      state.currentEndDate = payload.currentEndDate;
      if (payload.subscriptionId) {
        state.subscriptionId = payload.subscriptionId;
      }
      if (payload.downgraded) {
        state.downgraded = payload.downgraded;
      }

      if (payload.downgradedPlanName) {
        state.downgradedPlanName = payload.downgradedPlanName;
      }
      if (payload.lastDowngradedPlanName) {
        state.lastDowngradedPlanName = payload.lastDowngradedPlanName;
      }
      if (payload.lastDowngradedPlanEndDate) {
        state.lastDowngradedPlanEndDate = payload.lastDowngradedPlanEndDate;
      }
      if (payload.event) {
        state.event = payload?.event;
      }
      if (payload.eventDetails) {
        state.eventDetails = payload?.eventDetails;
      }
      state.isLoaded = true;
    },
    resetEvent(state): any {
      state.event = '';
      state.eventDetails = {};
    }
  },

  /* extraReducers: builder => {
    builder.addCase(
      extraActions.fetchSubscriptionDetails.fulfilled,
      (state, action: PayloadAction<{ subscription_status: string; unique_code: string }>) => {
        state.subscription_status = action.payload.subscription_status
        state.unique_code = action.payload.unique_code
      },
    )
  }, */
})

export default subscriptionSlice.reducer
export const subscriptionActions = {
  ...subscriptionSlice.actions,
  ...extraActions,
}
