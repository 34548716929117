import { remoteApi } from './utils/constant'
import { get, post as internalPost } from './utils/internalRequest'
import { post } from './utils/request'

export default {
  userInfo: ({ ...props }) =>
    post({
      url: remoteApi().webApi_userInfo,
      data: null,
      headers: {
        params: { ...props },
      },
    }),

  login: (data: any) =>
    post({
      url: remoteApi().webApi_login,
      data,
    }),

  judgeUserExist: (data: any, { ...props }) =>
    post({
      url: remoteApi().webApi_judgeUserExist,
      data,
      headers: {
        params: { ...props },
      },
    }),

  verify_code_image_create: ({ ...props }) =>
    post({
      url: remoteApi().webApi_verify_code_image_create,
      data: null,
      headers: {
        params: { ...props },
      },
    }),

  checkPhone: (data: any, { ...props }) =>
    post({
      url: remoteApi().webApi_checkPhone,
      data,
      headers: {
        params: { ...props },
      },
    }),

  checkEmail: (token: string, data: any, { ...props }) =>
    post({
      url: remoteApi().webApi_checkEmail,
      data,
      headers: {
        token,
        params: { ...props },
      },
    }),

  checkPassword: (token: string, data: any, { ...props }) =>
    post({
      url: remoteApi().webApi_checkPassword,
      data,
      headers: {
        token,
        params: { ...props },
      },
    }),

  forwardToEmail: (data: any, { ...props }) =>
    post({
      url: remoteApi().webApi_forwardToEmail,
      data,
      headers: {
        params: { ...props },
      },
    }),

  webApi_verify_code_create: (data: any, { ...props }) =>
    post({
      url: remoteApi().webApi_verify_code_create,
      data,
      headers: {
        params: { ...props },
      },
    }),

  register: (data: any, { ...props }) =>
    post({
      url: remoteApi().webApi_register,
      data,
      headers: {
        params: { ...props },
      },
    }),

  userDetails: ({ ...props }) =>
    get({
      url: remoteApi().user_details,
      headers: {
        params: { ...props },
      },
    }),

  verifyPassword: (data: any, { ...props }) =>
    internalPost({
      url: remoteApi().verify_password,
      data,
      headers: {
        params: { ...props },
      },
    }),

  sendOtp: (data: any, { ...props }) =>
    internalPost({
      url: remoteApi().send_otp,
      data,
      headers: {
        params: { ...props },
      },
    }),

  loginWithOtp: (data: any, { ...props }) =>
    internalPost({
      url: remoteApi().login_with_otp,
      data,
      headers: {
        params: { ...props },
      },
    }),

  verifyOtp: (data: any, { ...props }) =>
    internalPost({
      url: remoteApi().verify_otp,
      data,
      headers: {
        params: { ...props },
      },
    }),
  getUserOnBoardingStatus: () =>
    get({
      url: remoteApi().user_onboarding_status,
      headers: {
        params: {},
      },
    }),
  getEditorTutorialStatus: () =>
    get({
      url: remoteApi().get_tutorial_flag,
      headers: {
        params: {},
      },
      completeResp: true,
    }),
  setEditorTutorialStatus: (data: any) =>
    internalPost({
      url: remoteApi().set_tutorial_flag,
      data,
    }),
  logExceptions: (data: any, { ...props }) =>
    internalPost({
      url: remoteApi().log_exceptions,
      data,
      headers: {
        params: { ...props },
      },
    }),
  getTranslations: (data: any, { ...props }) =>
    internalPost({
      url: remoteApi().get_translations,
      data,
      headers: {
        params: { ...props },
      },
    }),

  // user_bind: (data: any, { ...props }) =>
  //   post({
  //     url: remoteApi().webApi_user_bind,
  //     data,
  //     headers: {
  //       params: { ...props },
  //     },
  //   }),

  // user_unbind: (data, { ...props }) =>
  //   post({
  //     url: remoteApi().webApi_user_unbind,
  //     data,
  //     headers: {
  //       params: { ...props },
  //     },
  //   }),

  // user_get_third_part: (data, { ...props }) =>
  //   get({
  //     url: remoteApi().webApi_user_third_part_info,
  //     data,
  //     headers: {
  //       params: { ...props },
  //     },
  //   }),
}
