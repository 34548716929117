import { getUserId } from '@/utils/auth'
import { materialPageSize } from '@/utils/constant'
import enumConfig from '@/utils/enum'
import { remoteApi } from './utils/constant'
import { get, post } from './utils/request'

export default {
  material_list: ({ ...props } = {}) =>
    get({
      url: remoteApi().webApi_material_list,
      headers: {
        params: { ...props, userId: getUserId() },
      },
    }),

  material_all: (data: any, { ...props } = {}) =>
    post({
      url: remoteApi().webApi_material_all,
      data,
      headers: {
        params: { ...props },
      },
    }),

  material_categories: ({ ...props }: any = {}) => {
    const isPanel = props.isPanel
    delete props.isPanel
    return new Promise(async function (resole, reject) {
      const res1 = await get({
        url: remoteApi().webApi_material_categories,
        headers: {
          params: { ...props },
        },
      }).catch(reject)
      if (props.type === enumConfig.mType.effectfx && !isPanel) {
        const res2 = await get({
          url: remoteApi().webApi_material_categories,
          headers: {
            params: { type: enumConfig.mType.particle },
          },
        }).catch(reject)
        res1.materialCategories = [
          ...res1.materialCategories,
          ...res2.materialCategories,
        ]
      } else if (props.type === enumConfig.mType.captionstyle && !isPanel) {
        const res2 = await get({
          url: remoteApi().webApi_material_categories,
          headers: {
            params: { type: enumConfig.mType.compoundCaption },
          },
        }).catch(reject)
        res1.materialCategories = [
          ...res1.materialCategories,
          ...res2.materialCategories,
        ]
      }
      resole(res1)
    })
  },

  material_overview_list: async ({ ...props }: any = {}) => {
    const isPanel = props.isPanel
    delete props.isPanel
    return new Promise(async function (resole, reject) {
      const res1 = await get({
        url: remoteApi().webApi_material_private_overview_list,
        headers: {
          params: {
            ...props,
            page: 0,
            pageSize: materialPageSize,
            userId: getUserId(),
          },
        },
      }).catch(reject)

      if (props.type === enumConfig.mType.effectfx && !isPanel) {
        const res2 = await get({
          url: remoteApi().webApi_material_private_overview_list,
          headers: {
            params: {
              type: enumConfig.mType.particle,
              page: 0,
              pageSize: materialPageSize,
              userId: getUserId(),
            },
          },
        }).catch(reject)
        res1.materialCategories = [
          ...res1.materialCategories,
          ...res2.materialCategories,
        ]
      }

      if (props.type === enumConfig.mType.captionstyle) {
        const res2 = await get({
          url: remoteApi().webApi_material_private_overview_list,
          headers: {
            params: {
              type: enumConfig.mType.compoundCaption,
              page: 0,
              pageSize: materialPageSize,
              userId: getUserId(),
            },
          },
        }).catch(reject)
        res1.materialCategories = [
          ...res1.materialCategories,
          ...res2.materialCategories,
        ]
      }
      resole(res1)
    })
  },

  material_catalog: ({ ...props } = {}) =>
    get({
      url: remoteApi().webApi_material_catalog,
      headers: {
        params: { ...props },
      },
    }),

  material_recent_list: ({ ...props } = {}) =>
    get({
      url: remoteApi().webApi_material_private_recent_list,
      headers: {
        params: { ...props },
      },
    }),

  material_favourite_list: ({ ...props } = {}) =>
    get({
      url: remoteApi().webApi_material_private_favourite_list,
      headers: {
        params: { ...props },
      },
    }),

  material_recent_mark: (data: any, { ...props } = {}) =>
    post({
      url: remoteApi().webApi_material_private_recent_mark,
      data,
      headers: {
        params: { ...props },
      },
    }),

  material_favourite_mark: (data: any, { ...props } = {}) =>
    post({
      url: remoteApi().webApi_material_private_favourite_mark,
      data,
      headers: {
        params: { ...props },
      },
    }),

  material_add: (data: any, { ...props } = {}) =>
    post({
      url: remoteApi().webApi_material_add,
      data,
      headers: {
        params: { ...props },
      },
    }),

  material_arrearage: (data: any, { ...props } = {}) =>
    post({
      url: remoteApi().webApi_material_arrearage,
      data,
      headers: {
        params: { ...props },
      },
    }),

  material_submit_material: (data: any, { ...props } = {}) =>
    post({
      url: remoteApi().webApi_material_submitMaterial,
      data,
      headers: {
        params: { ...props },
      },
    }),

  material_private_update: (data: any) =>
    post({
      url: remoteApi().webApi_material_private_update,
      data,
    }),

  material_private_list: ({ ...props }) =>
    get({
      url: remoteApi().webApi_material_private_list,
      headers: {
        params: { ...props },
      },
    }),
}
